
:global {
	article,
	aside,
	details,
	figcaption,
	figure,
	footer,
	header,
	hgroup,
	main,
	menu,
	nav,
	section,
	summary {
		display: block;
	}
	audio,
	canvas,
	progress,
	video {
		display: inline-block;
		vertical-align: baseline;
	}
	audio:not([controls]) {
		display: none;
		height: 0;
	}
	[hidden],
	template {
		display: none;
	}
	a:active,
	a:hover {
		outline: 0;
	}
	abbr[title] {
		border-bottom: 1px dotted;
	}
	b,
	strong {
		font-weight: bold;
	}
	dfn {
		font-style: italic;
	}
	h1 {
		font-size: 2em;
		margin: 0.67em 0;
	}
	mark {
		background: #ff0;
		color: #000;
	}
	small {
		font-size: 80%;
	}
	sub,
	sup {
		font-size: 75%;
		line-height: 0;
		position: relative;
		vertical-align: baseline;
	}
	sup {
		top: -0.5em;
	}
	sub {
		bottom: -0.25em;
	}
	svg:not(:root) {
		overflow: hidden;
	}
	code,
	kbd,
	pre,
	samp {
		font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
		font-size: 1em;
	}
	button,
	input,
	optgroup,
	select,
	textarea {
		color: inherit;
		font: inherit;
		margin: 0;
	}
	button {
		overflow: visible;
	}
	button,
	select {
		text-transform: none;
	}
	button,
	html input[type="button"],
	input[type="reset"],
	input[type="submit"] {
		-webkit-appearance: button;
		cursor: pointer;
	}
	button[disabled],
	html input[disabled] {
		cursor: default;
	}
	button::-moz-focus-inner,
	input::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
	input {
		line-height: normal;
	}
	input[type="checkbox"],
	input[type="radio"] {
		box-sizing: border-box;
		padding: 0;
	}
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		height: auto;
	}
	input[type="search"] {
		-webkit-appearance: textfield;
		-moz-box-sizing: content-box;
		-webkit-box-sizing: content-box;
		box-sizing: content-box;
	}
	input[type="search"]::-webkit-search-cancel-button,
	input[type="search"]::-webkit-search-decoration {
		-webkit-appearance: none;
	}
	fieldset {
		border: 1px solid #c0c0c0;
		margin: 0 2px;
		padding: 0.35em 0.625em 0.75em;
	}
	legend {
		border: 0;
		padding: 0;
	}
	textarea {
		overflow: auto;
	}
	optgroup {
		font-weight: bold;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	td,
	th {
		padding: 0;
	}
	@media print {
		*,
		*:before,
		*:after {
			background: transparent !important;
			color: #000 !important;
			box-shadow: none !important;
			text-shadow: none !important;
		}
		a,
		a:visited {
			text-decoration: underline;
		}
		a[href]:after {
			content: " (" attr(href) ")";
		}
		abbr[title]:after {
			content: " (" attr(title) ")";
		}
		a[href^="#"]:after,
		a[href^="javascript:"]:after {
			content: "";
		}
		pre,
		blockquote {
			border: 1px solid #999;
			page-break-inside: avoid;
		}
		thead {
			display: table-header-group;
		}
		tr,
		img {
			page-break-inside: avoid;
		}
		img {
			max-width: 100% !important;
		}
		p,
		h2,
		h3 {
			orphans: 3;
			widows: 3;
		}
		h2,
		h3 {
			page-break-after: avoid;
		}
		select {
			background: #fff !important;
		}
		.navbar {
			display: none;
		}
		.btn > .caret,
		.dropup > .btn > .caret {
			border-top-color: #000 !important;
		}
		.label {
			border: 1px solid #000;
		}
		.table {
			border-collapse: collapse !important;
		}
		.table td,
		.table th {
			background-color: #fff !important;
		}
		.table-bordered th,
		.table-bordered td {
			border: 1px solid #ddd !important;
		}
	}
	* {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	*:before,
	*:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	html {
		font-family: sans-serif;
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
		font-size: 10px;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
	body {
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size: 14px;
		line-height: 1.42857143;
		color: #333333;
		background-color: #fff;
		margin: 0;
	}
	input,
	button,
	select,
	textarea {
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
	}
	a {
		background-color: transparent;
		color: #337ab7;
		text-decoration: none;
	}
	a:hover,
	a:focus {
		color: #23527c;
		text-decoration: underline;
	}
	a:focus {
		outline: thin dotted;
		outline: 5px auto -webkit-focus-ring-color;
		outline-offset: -2px;
	}
	figure {
		margin: 0;
		margin: 1em 40px;
	}
	img {
		vertical-align: middle;
		border: 0;
	}
	.img-responsive {
		display: block;
		max-width: 100%;
		height: auto;
	}
	.img-rounded {
		border-radius: 6px;
	}
	.img-thumbnail {
		padding: 4px;
		line-height: 1.42857143;
		background-color: #fff;
		border: 1px solid #ddd;
		border-radius: 4px;
		-webkit-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		display: inline-block;
		max-width: 100%;
		height: auto;
	}
	.img-circle {
		border-radius: 50%;
	}
	hr {
		margin-top: 20px;
		margin-bottom: 20px;
		border: 0;
		border-top: 1px solid #eeeeee;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
		height: 0;
	}
	.sr-only {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		border: 0;
	}
	.sr-only-focusable:active,
	.sr-only-focusable:focus {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6 {
		font-family: inherit;
		font-weight: 500;
		line-height: 1.1;
		color: inherit;
	}
	h1 small,
	h2 small,
	h3 small,
	h4 small,
	h5 small,
	h6 small,
	.h1 small,
	.h2 small,
	.h3 small,
	.h4 small,
	.h5 small,
	.h6 small,
	h1 .small,
	h2 .small,
	h3 .small,
	h4 .small,
	h5 .small,
	h6 .small,
	.h1 .small,
	.h2 .small,
	.h3 .small,
	.h4 .small,
	.h5 .small,
	.h6 .small {
		font-weight: normal;
		line-height: 1;
		color: #777777;
	}
	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3 {
		margin-top: 20px;
		margin-bottom: 10px;
	}
	h1 small,
	.h1 small,
	h2 small,
	.h2 small,
	h3 small,
	.h3 small,
	h1 .small,
	.h1 .small,
	h2 .small,
	.h2 .small,
	h3 .small,
	.h3 .small {
		font-size: 65%;
	}
	h4,
	.h4,
	h5,
	.h5,
	h6,
	.h6 {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	h4 small,
	.h4 small,
	h5 small,
	.h5 small,
	h6 small,
	.h6 small,
	h4 .small,
	.h4 .small,
	h5 .small,
	.h5 .small,
	h6 .small,
	.h6 .small {
		font-size: 75%;
	}
	h1,
	.h1 {
		font-size: 36px;
	}
	h2,
	.h2 {
		font-size: 30px;
	}
	h3,
	.h3 {
		font-size: 24px;
	}
	h4,
	.h4 {
		font-size: 18px;
	}
	h5,
	.h5 {
		font-size: 14px;
	}
	h6,
	.h6 {
		font-size: 12px;
	}
	p {
		margin: 0 0 10px;
	}
	.lead {
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 300;
		line-height: 1.4;
	}
	@media (min-width: 992px) {
		.lead {
			font-size: 21px;
		}
	}
	small,
	.small {
		font-size: 85%;
	}
	mark,
	.mark {
		background-color: #fcf8e3;
		padding: 0.2em;
	}
	.text-left {
		text-align: left;
	}
	.text-right {
		text-align: right;
	}
	.text-center {
		text-align: center;
	}
	.text-justify {
		text-align: justify;
	}
	.text-nowrap {
		white-space: nowrap;
	}
	.text-lowercase {
		text-transform: lowercase;
	}
	.text-uppercase {
		text-transform: uppercase;
	}
	.text-capitalize {
		text-transform: capitalize;
	}
	.text-muted {
		color: #777777;
	}
	.text-primary {
		color: #337ab7;
	}
	a.text-primary:hover {
		color: #286090;
	}
	.text-success {
		color: #3c763d;
	}
	a.text-success:hover {
		color: #2b542c;
	}
	.text-info {
		color: #31708f;
	}
	a.text-info:hover {
		color: #245269;
	}
	.text-warning {
		color: #8a6d3b;
	}
	a.text-warning:hover {
		color: #66512c;
	}
	.text-danger {
		color: #a94442;
	}
	a.text-danger:hover {
		color: #843534;
	}
	.bg-primary {
		color: #fff;
		background-color: #337ab7;
	}
	a.bg-primary:hover {
		background-color: #286090;
	}
	.bg-success {
		background-color: #dff0d8;
	}
	a.bg-success:hover {
		background-color: #c1e2b3;
	}
	.bg-info {
		background-color: #d9edf7;
	}
	a.bg-info:hover {
		background-color: #afd9ee;
	}
	.bg-warning {
		background-color: #fcf8e3;
	}
	a.bg-warning:hover {
		background-color: #f7ecb5;
	}
	.bg-danger {
		background-color: #f2dede;
	}
	a.bg-danger:hover {
		background-color: #e4b9b9;
	}
	.page-header {
		padding-bottom: 9px;
		margin: 40px 0 20px;
		border-bottom: 1px solid #eeeeee;
	}
	ul,
	ol {
		margin-top: 0;
		margin-bottom: 10px;
	}
	ul ul,
	ol ul,
	ul ol,
	ol ol {
		margin-bottom: 0;
	}
	.list-unstyled {
		padding-left: 0;
		list-style: none;
	}
	.list-inline {
		padding-left: 0;
		list-style: none;
		margin-left: -5px;
	}
	.list-inline > li {
		display: inline-block;
		padding-left: 5px;
		padding-right: 5px;
	}
	dl {
		margin-top: 0;
		margin-bottom: 20px;
	}
	dt,
	dd {
		line-height: 1.42857143;
	}
	dt {
		font-weight: bold;
	}
	dd {
		margin-left: 0;
	}
	@media (min-width: 992px) {
		.dl-horizontal dt {
			float: left;
			width: 160px;
			clear: left;
			text-align: right;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.dl-horizontal dd {
			margin-left: 180px;
		}
	}
	abbr[title],
	abbr[data-original-title] {
		cursor: help;
		border-bottom: 1px dotted #777777;
	}
	.initialism {
		font-size: 90%;
		text-transform: uppercase;
	}
	blockquote {
		padding: 10px 20px;
		margin: 0 0 20px;
		font-size: 17.5px;
		border-left: 5px solid #eeeeee;
	}
	blockquote p:last-child,
	blockquote ul:last-child,
	blockquote ol:last-child {
		margin-bottom: 0;
	}
	blockquote footer,
	blockquote small,
	blockquote .small {
		display: block;
		font-size: 80%;
		line-height: 1.42857143;
		color: #777777;
	}
	blockquote footer:before,
	blockquote small:before,
	blockquote .small:before {
		content: "\2014   \A0";
	}
	.blockquote-reverse,
	blockquote.pull-right {
		padding-right: 15px;
		padding-left: 0;
		border-right: 5px solid #eeeeee;
		border-left: 0;
		text-align: right;
	}
	.blockquote-reverse footer:before,
	blockquote.pull-right footer:before,
	.blockquote-reverse small:before,
	blockquote.pull-right small:before,
	.blockquote-reverse .small:before,
	blockquote.pull-right .small:before {
		content: "";
	}
	.blockquote-reverse footer:after,
	blockquote.pull-right footer:after,
	.blockquote-reverse small:after,
	blockquote.pull-right small:after,
	.blockquote-reverse .small:after,
	blockquote.pull-right .small:after {
		content: "\A0   \2014";
	}
	address {
		margin-bottom: 20px;
		font-style: normal;
		line-height: 1.42857143;
	}
	code {
		padding: 2px 4px;
		font-size: 90%;
		color: #c7254e;
		background-color: #f9f2f4;
		border-radius: 4px;
	}
	kbd {
		padding: 2px 4px;
		font-size: 90%;
		color: #fff;
		background-color: #333;
		border-radius: 3px;
		box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
	}
	kbd kbd {
		padding: 0;
		font-size: 100%;
		font-weight: bold;
		box-shadow: none;
	}
	pre {
		overflow: auto;
		display: block;
		padding: 9.5px;
		margin: 0 0 10px;
		font-size: 13px;
		line-height: 1.42857143;
		word-break: break-all;
		word-wrap: break-word;
		color: #333333;
		background-color: #f5f5f5;
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	pre code {
		padding: 0;
		font-size: inherit;
		color: inherit;
		white-space: pre-wrap;
		background-color: transparent;
		border-radius: 0;
	}
	.pre-scrollable {
		max-height: 340px;
		overflow-y: scroll;
	}
	.container {
		margin-right: auto;
		margin-left: auto;
		padding-left: 15px;
		padding-right: 15px;
	}
	@media (min-width: 992px) {
		.container {
			width: 750px;
		}
	}
	@media (min-width: 992px) {
		.container {
			width: 970px;
		}
	}
	@media (min-width: 1200px) {
		.container {
			width: 1170px;
		}
	}
	.container-fluid {
		margin-right: auto;
		margin-left: auto;
		padding-left: 15px;
		padding-right: 15px;
	}
	.row {
		margin-left: -15px;
		margin-right: -15px;
	}
	.col-xs-1,
	.col-sm-1,
	.col-md-1,
	.col-lg-1,
	.col-xs-2,
	.col-sm-2,
	.col-md-2,
	.col-lg-2,
	.col-xs-3,
	.col-sm-3,
	.col-md-3,
	.col-lg-3,
	.col-xs-4,
	.col-sm-4,
	.col-md-4,
	.col-lg-4,
	.col-xs-5,
	.col-sm-5,
	.col-md-5,
	.col-lg-5,
	.col-xs-6,
	.col-sm-6,
	.col-md-6,
	.col-lg-6,
	.col-xs-7,
	.col-sm-7,
	.col-md-7,
	.col-lg-7,
	.col-xs-8,
	.col-sm-8,
	.col-md-8,
	.col-lg-8,
	.col-xs-9,
	.col-sm-9,
	.col-md-9,
	.col-lg-9,
	.col-xs-10,
	.col-sm-10,
	.col-md-10,
	.col-lg-10,
	.col-xs-11,
	.col-sm-11,
	.col-md-11,
	.col-lg-11,
	.col-xs-12,
	.col-sm-12,
	.col-md-12,
	.col-lg-12 {
		position: relative;
		min-height: 1px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.col-xs-1,
	.col-xs-2,
	.col-xs-3,
	.col-xs-4,
	.col-xs-5,
	.col-xs-6,
	.col-xs-7,
	.col-xs-8,
	.col-xs-9,
	.col-xs-10,
	.col-xs-11,
	.col-xs-12 {
		float: left;
	}
	.col-xs-12 {
		width: 100%;
	}
	.col-xs-11 {
		width: 91.66666667%;
	}
	.col-xs-10 {
		width: 83.33333333%;
	}
	.col-xs-9 {
		width: 75%;
	}
	.col-xs-8 {
		width: 66.66666667%;
	}
	.col-xs-7 {
		width: 58.33333333%;
	}
	.col-xs-6 {
		width: 50%;
	}
	.col-xs-5 {
		width: 41.66666667%;
	}
	.col-xs-4 {
		width: 33.33333333%;
	}
	.col-xs-3 {
		width: 25%;
	}
	.col-xs-2 {
		width: 16.66666667%;
	}
	.col-xs-1 {
		width: 8.33333333%;
	}
	.col-xs-pull-12 {
		right: 100%;
	}
	.col-xs-pull-11 {
		right: 91.66666667%;
	}
	.col-xs-pull-10 {
		right: 83.33333333%;
	}
	.col-xs-pull-9 {
		right: 75%;
	}
	.col-xs-pull-8 {
		right: 66.66666667%;
	}
	.col-xs-pull-7 {
		right: 58.33333333%;
	}
	.col-xs-pull-6 {
		right: 50%;
	}
	.col-xs-pull-5 {
		right: 41.66666667%;
	}
	.col-xs-pull-4 {
		right: 33.33333333%;
	}
	.col-xs-pull-3 {
		right: 25%;
	}
	.col-xs-pull-2 {
		right: 16.66666667%;
	}
	.col-xs-pull-1 {
		right: 8.33333333%;
	}
	.col-xs-pull-0 {
		right: auto;
	}
	.col-xs-push-12 {
		left: 100%;
	}
	.col-xs-push-11 {
		left: 91.66666667%;
	}
	.col-xs-push-10 {
		left: 83.33333333%;
	}
	.col-xs-push-9 {
		left: 75%;
	}
	.col-xs-push-8 {
		left: 66.66666667%;
	}
	.col-xs-push-7 {
		left: 58.33333333%;
	}
	.col-xs-push-6 {
		left: 50%;
	}
	.col-xs-push-5 {
		left: 41.66666667%;
	}
	.col-xs-push-4 {
		left: 33.33333333%;
	}
	.col-xs-push-3 {
		left: 25%;
	}
	.col-xs-push-2 {
		left: 16.66666667%;
	}
	.col-xs-push-1 {
		left: 8.33333333%;
	}
	.col-xs-push-0 {
		left: auto;
	}
	.col-xs-offset-12 {
		margin-left: 100%;
	}
	.col-xs-offset-11 {
		margin-left: 91.66666667%;
	}
	.col-xs-offset-10 {
		margin-left: 83.33333333%;
	}
	.col-xs-offset-9 {
		margin-left: 75%;
	}
	.col-xs-offset-8 {
		margin-left: 66.66666667%;
	}
	.col-xs-offset-7 {
		margin-left: 58.33333333%;
	}
	.col-xs-offset-6 {
		margin-left: 50%;
	}
	.col-xs-offset-5 {
		margin-left: 41.66666667%;
	}
	.col-xs-offset-4 {
		margin-left: 33.33333333%;
	}
	.col-xs-offset-3 {
		margin-left: 25%;
	}
	.col-xs-offset-2 {
		margin-left: 16.66666667%;
	}
	.col-xs-offset-1 {
		margin-left: 8.33333333%;
	}
	.col-xs-offset-0 {
		margin-left: 0%;
	}
	@media (min-width: 992px) {
		.col-sm-1,
		.col-sm-2,
		.col-sm-3,
		.col-sm-4,
		.col-sm-5,
		.col-sm-6,
		.col-sm-7,
		.col-sm-8,
		.col-sm-9,
		.col-sm-10,
		.col-sm-11,
		.col-sm-12 {
			float: left;
		}
		.col-sm-12 {
			width: 100%;
		}
		.col-sm-11 {
			width: 91.66666667%;
		}
		.col-sm-10 {
			width: 83.33333333%;
		}
		.col-sm-9 {
			width: 75%;
		}
		.col-sm-8 {
			width: 66.66666667%;
		}
		.col-sm-7 {
			width: 58.33333333%;
		}
		.col-sm-6 {
			width: 50%;
		}
		.col-sm-5 {
			width: 41.66666667%;
		}
		.col-sm-4 {
			width: 33.33333333%;
		}
		.col-sm-3 {
			width: 25%;
		}
		.col-sm-2 {
			width: 16.66666667%;
		}
		.col-sm-1 {
			width: 8.33333333%;
		}
		.col-sm-pull-12 {
			right: 100%;
		}
		.col-sm-pull-11 {
			right: 91.66666667%;
		}
		.col-sm-pull-10 {
			right: 83.33333333%;
		}
		.col-sm-pull-9 {
			right: 75%;
		}
		.col-sm-pull-8 {
			right: 66.66666667%;
		}
		.col-sm-pull-7 {
			right: 58.33333333%;
		}
		.col-sm-pull-6 {
			right: 50%;
		}
		.col-sm-pull-5 {
			right: 41.66666667%;
		}
		.col-sm-pull-4 {
			right: 33.33333333%;
		}
		.col-sm-pull-3 {
			right: 25%;
		}
		.col-sm-pull-2 {
			right: 16.66666667%;
		}
		.col-sm-pull-1 {
			right: 8.33333333%;
		}
		.col-sm-pull-0 {
			right: auto;
		}
		.col-sm-push-12 {
			left: 100%;
		}
		.col-sm-push-11 {
			left: 91.66666667%;
		}
		.col-sm-push-10 {
			left: 83.33333333%;
		}
		.col-sm-push-9 {
			left: 75%;
		}
		.col-sm-push-8 {
			left: 66.66666667%;
		}
		.col-sm-push-7 {
			left: 58.33333333%;
		}
		.col-sm-push-6 {
			left: 50%;
		}
		.col-sm-push-5 {
			left: 41.66666667%;
		}
		.col-sm-push-4 {
			left: 33.33333333%;
		}
		.col-sm-push-3 {
			left: 25%;
		}
		.col-sm-push-2 {
			left: 16.66666667%;
		}
		.col-sm-push-1 {
			left: 8.33333333%;
		}
		.col-sm-push-0 {
			left: auto;
		}
		.col-sm-offset-12 {
			margin-left: 100%;
		}
		.col-sm-offset-11 {
			margin-left: 91.66666667%;
		}
		.col-sm-offset-10 {
			margin-left: 83.33333333%;
		}
		.col-sm-offset-9 {
			margin-left: 75%;
		}
		.col-sm-offset-8 {
			margin-left: 66.66666667%;
		}
		.col-sm-offset-7 {
			margin-left: 58.33333333%;
		}
		.col-sm-offset-6 {
			margin-left: 50%;
		}
		.col-sm-offset-5 {
			margin-left: 41.66666667%;
		}
		.col-sm-offset-4 {
			margin-left: 33.33333333%;
		}
		.col-sm-offset-3 {
			margin-left: 25%;
		}
		.col-sm-offset-2 {
			margin-left: 16.66666667%;
		}
		.col-sm-offset-1 {
			margin-left: 8.33333333%;
		}
		.col-sm-offset-0 {
			margin-left: 0%;
		}
	}
	@media (min-width: 992px) {
		.col-md-1,
		.col-md-2,
		.col-md-3,
		.col-md-4,
		.col-md-5,
		.col-md-6,
		.col-md-7,
		.col-md-8,
		.col-md-9,
		.col-md-10,
		.col-md-11,
		.col-md-12 {
			float: left;
		}
		.col-md-12 {
			width: 100%;
		}
		.col-md-11 {
			width: 91.66666667%;
		}
		.col-md-10 {
			width: 83.33333333%;
		}
		.col-md-9 {
			width: 75%;
		}
		.col-md-8 {
			width: 66.66666667%;
		}
		.col-md-7 {
			width: 58.33333333%;
		}
		.col-md-6 {
			width: 50%;
		}
		.col-md-5 {
			width: 41.66666667%;
		}
		.col-md-4 {
			width: 33.33333333%;
		}
		.col-md-3 {
			width: 25%;
		}
		.col-md-2 {
			width: 16.66666667%;
		}
		.col-md-1 {
			width: 8.33333333%;
		}
		.col-md-pull-12 {
			right: 100%;
		}
		.col-md-pull-11 {
			right: 91.66666667%;
		}
		.col-md-pull-10 {
			right: 83.33333333%;
		}
		.col-md-pull-9 {
			right: 75%;
		}
		.col-md-pull-8 {
			right: 66.66666667%;
		}
		.col-md-pull-7 {
			right: 58.33333333%;
		}
		.col-md-pull-6 {
			right: 50%;
		}
		.col-md-pull-5 {
			right: 41.66666667%;
		}
		.col-md-pull-4 {
			right: 33.33333333%;
		}
		.col-md-pull-3 {
			right: 25%;
		}
		.col-md-pull-2 {
			right: 16.66666667%;
		}
		.col-md-pull-1 {
			right: 8.33333333%;
		}
		.col-md-pull-0 {
			right: auto;
		}
		.col-md-push-12 {
			left: 100%;
		}
		.col-md-push-11 {
			left: 91.66666667%;
		}
		.col-md-push-10 {
			left: 83.33333333%;
		}
		.col-md-push-9 {
			left: 75%;
		}
		.col-md-push-8 {
			left: 66.66666667%;
		}
		.col-md-push-7 {
			left: 58.33333333%;
		}
		.col-md-push-6 {
			left: 50%;
		}
		.col-md-push-5 {
			left: 41.66666667%;
		}
		.col-md-push-4 {
			left: 33.33333333%;
		}
		.col-md-push-3 {
			left: 25%;
		}
		.col-md-push-2 {
			left: 16.66666667%;
		}
		.col-md-push-1 {
			left: 8.33333333%;
		}
		.col-md-push-0 {
			left: auto;
		}
		.col-md-offset-12 {
			margin-left: 100%;
		}
		.col-md-offset-11 {
			margin-left: 91.66666667%;
		}
		.col-md-offset-10 {
			margin-left: 83.33333333%;
		}
		.col-md-offset-9 {
			margin-left: 75%;
		}
		.col-md-offset-8 {
			margin-left: 66.66666667%;
		}
		.col-md-offset-7 {
			margin-left: 58.33333333%;
		}
		.col-md-offset-6 {
			margin-left: 50%;
		}
		.col-md-offset-5 {
			margin-left: 41.66666667%;
		}
		.col-md-offset-4 {
			margin-left: 33.33333333%;
		}
		.col-md-offset-3 {
			margin-left: 25%;
		}
		.col-md-offset-2 {
			margin-left: 16.66666667%;
		}
		.col-md-offset-1 {
			margin-left: 8.33333333%;
		}
		.col-md-offset-0 {
			margin-left: 0%;
		}
	}
	@media (min-width: 1200px) {
		.col-lg-1,
		.col-lg-2,
		.col-lg-3,
		.col-lg-4,
		.col-lg-5,
		.col-lg-6,
		.col-lg-7,
		.col-lg-8,
		.col-lg-9,
		.col-lg-10,
		.col-lg-11,
		.col-lg-12 {
			float: left;
		}
		.col-lg-12 {
			width: 100%;
		}
		.col-lg-11 {
			width: 91.66666667%;
		}
		.col-lg-10 {
			width: 83.33333333%;
		}
		.col-lg-9 {
			width: 75%;
		}
		.col-lg-8 {
			width: 66.66666667%;
		}
		.col-lg-7 {
			width: 58.33333333%;
		}
		.col-lg-6 {
			width: 50%;
		}
		.col-lg-5 {
			width: 41.66666667%;
		}
		.col-lg-4 {
			width: 33.33333333%;
		}
		.col-lg-3 {
			width: 25%;
		}
		.col-lg-2 {
			width: 16.66666667%;
		}
		.col-lg-1 {
			width: 8.33333333%;
		}
		.col-lg-pull-12 {
			right: 100%;
		}
		.col-lg-pull-11 {
			right: 91.66666667%;
		}
		.col-lg-pull-10 {
			right: 83.33333333%;
		}
		.col-lg-pull-9 {
			right: 75%;
		}
		.col-lg-pull-8 {
			right: 66.66666667%;
		}
		.col-lg-pull-7 {
			right: 58.33333333%;
		}
		.col-lg-pull-6 {
			right: 50%;
		}
		.col-lg-pull-5 {
			right: 41.66666667%;
		}
		.col-lg-pull-4 {
			right: 33.33333333%;
		}
		.col-lg-pull-3 {
			right: 25%;
		}
		.col-lg-pull-2 {
			right: 16.66666667%;
		}
		.col-lg-pull-1 {
			right: 8.33333333%;
		}
		.col-lg-pull-0 {
			right: auto;
		}
		.col-lg-push-12 {
			left: 100%;
		}
		.col-lg-push-11 {
			left: 91.66666667%;
		}
		.col-lg-push-10 {
			left: 83.33333333%;
		}
		.col-lg-push-9 {
			left: 75%;
		}
		.col-lg-push-8 {
			left: 66.66666667%;
		}
		.col-lg-push-7 {
			left: 58.33333333%;
		}
		.col-lg-push-6 {
			left: 50%;
		}
		.col-lg-push-5 {
			left: 41.66666667%;
		}
		.col-lg-push-4 {
			left: 33.33333333%;
		}
		.col-lg-push-3 {
			left: 25%;
		}
		.col-lg-push-2 {
			left: 16.66666667%;
		}
		.col-lg-push-1 {
			left: 8.33333333%;
		}
		.col-lg-push-0 {
			left: auto;
		}
		.col-lg-offset-12 {
			margin-left: 100%;
		}
		.col-lg-offset-11 {
			margin-left: 91.66666667%;
		}
		.col-lg-offset-10 {
			margin-left: 83.33333333%;
		}
		.col-lg-offset-9 {
			margin-left: 75%;
		}
		.col-lg-offset-8 {
			margin-left: 66.66666667%;
		}
		.col-lg-offset-7 {
			margin-left: 58.33333333%;
		}
		.col-lg-offset-6 {
			margin-left: 50%;
		}
		.col-lg-offset-5 {
			margin-left: 41.66666667%;
		}
		.col-lg-offset-4 {
			margin-left: 33.33333333%;
		}
		.col-lg-offset-3 {
			margin-left: 25%;
		}
		.col-lg-offset-2 {
			margin-left: 16.66666667%;
		}
		.col-lg-offset-1 {
			margin-left: 8.33333333%;
		}
		.col-lg-offset-0 {
			margin-left: 0%;
		}
	}
}
