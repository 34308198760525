@import "./bootstrap-clone.scss";
@import "./colors.scss";
@import "./variables.scss";

@font-face {
	font-family: "MarkPro";
	font-style: normal;
	font-weight: normal;
	font-display: swap;
	src: local("MarkPro"), url("./fonts/MarkPro-Light.woff2") format("woff2"), /* Modern Browsers */ url("./fonts/MarkPro-Light.woff") format("woff"),
		url("./fonts/MarkPro-Light.otf") format("opentype");
	unicode-range: U+000-5FF;
}

@font-face {
	font-family: "MarkPro";
	font-style: normal;
	font-weight: bold;
	font-display: swap;
	src: local("MarkPro"), url("./fonts/MarkPro-Bold.woff2") format("woff2"), /* Modern Browsers */ url("./fonts/MarkPro-Bold.woff") format("woff"), url("./fonts/MarkPro-Bold.otf") format("opentype");
	unicode-range: U+000-5FF;
}

@font-face {
	font-family: "icomoon";
	font-display: swap;
	src: url("./fonts/icomoon.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

:global {
	html,
	body {
		padding: 0;
		margin: 0;
		height: 100%;
	}

	body {
		color: #353535;
		font-family: "MarkPro", sans-serif;
		font-size: 1.6rem;
	}

	h1 {
		font-size: 2.8rem;
		line-height: 3.6rem;
		font-weight: bold;
	}

	h2 {
		font-size: 2.2rem;
		line-height: 2.6rem;
		font-weight: bold;
	}

	h3 {
		font-size: 1.8rem;
		line-height: 2.2rem;
		font-weight: bold;
	}

	h4 {
		font-size: 1.6rem;
		line-height: 2.2rem;
		font-weight: bold;
	}

	iframe {
		border: none;
	}

	#app-container {
		min-height: 100%;
		max-width: 100%;
		margin: 0 auto;
		float: none;
	}

	.fullHeight {
		height: 100%;
	}

	.mainArea {
		position: static;
		height: auto;
		overflow-y: auto;
		width: 100%;

		&.withAlerts {
			height: auto;
		}
		&.withSingleAlert {
			height: auto;
		}

		@media screen and (min-width: $desktop-breakpoint) {
			position: static;
			height: auto !important;
			overflow-y: visible;
			padding-top: 11.6rem;

			&.withAlerts {
				padding-top: 18.4rem;
			}

			&.openingMenu {
				height: auto !important;
				overflow-y: auto;
				padding-top: 13.8rem;

				&.withAlerts {
					height: auto !important;
					padding-top: 18.4rem;
				}
				&.withSingleAlert {
					padding-top: 21.2rem;
					height: auto !important;
				}
			}

			&:focus {
				border: none;
				outline: none;
			}
		}

		.mainSection {
			width: 100%;
			min-height: 100vh;
			float: left;
			background: $color-light-grey;
			@media screen and (min-width: $desktop-breakpoint) {
				min-height: 70rem;
			}
		}
	}
}
