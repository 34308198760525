// Put external css and scss files from node_modules here

@import "../../node_modules/react-datepicker/dist/react-datepicker.min.css";

.react-datepicker__current-month,
.react-datepicker-time__header {
	font-size: 1.4rem;
}
.react-datepicker {
	font-size: 1.2rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	width: 3rem;
	height: 3rem;
	margin: 0.2rem;
	border-radius: 0;
	line-height: 2.9rem;
}

.react-datepicker__day-name {
	color: #888282;
	font-weight: bold;
}

.react-datepicker__day {
	text-align: center;
	color: rgb(45, 20, 110);
	font-family: MarkPro, sans-serif;
	font-weight: bold;
	font-size: 1.2rem;
}

.react-datepicker__day--selected {
	color: #fff;
}

.react-datepicker__day--disabled {
	color: #ccc;
}
