@import "../../variables.scss";

.pageContainer {
	background: linear-gradient(90deg, rgb(149, 231, 237) 0%, rgb(229, 247, 249) 100%);
	height: 100%;
	min-height: 70rem;

	& .centerContent {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		padding-top: 16rem;

		@media (min-width: $desktop-breakpoint) {
			padding-top: 24rem;
		}

		& .mainLetters {
			position: absolute;
			top: 5rem;
			font-size: 16rem;
			font-weight: bold;
			color: rgba(92, 203, 215, 0.3);
			z-index: 0;

			@media (min-width: $desktop-breakpoint) {
				top: 3rem;
				font-size: 30rem;
			}
		}

		& .busIcon {
			height: 9.3rem;
			z-index: 1;

			@media (min-width: $desktop-breakpoint) {
				height: 11.2rem;
			}
		}

		& .titleText {
			margin-top: 2.6rem;
			font-size: 2.8rem;
			font-weight: 500;

			@media (min-width: $desktop-breakpoint) {
				font-size: 3.8rem;
			}
		}

		& .subtitle {
			font-size: 1.8rem;
			margin-top: 2.4rem;

			@media (min-width: $desktop-breakpoint) {
				margin-top: 1.1rem;
				font-size: 2.4rem;
			}
		}

		& > button {
			margin-top: 3.6rem;

			@media (min-width: $desktop-breakpoint) {
				margin-top: 2.7rem;
				font-size: 1.6rem;
			}
		}
	}
}
